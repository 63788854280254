import store from '@/store'
import http from '@/utils/http'

let preApi = '';
if(store.state.mock && process.env.NODE_ENV == 'development') {
    preApi = '/mock';
}


//能耗管理-能耗表组添加
export function addEcGroup(params) {
    return http({
        url: `${preApi}/ec/addEcGroup`,
        method: "post",
        data: params
    })
}
//能耗管理-能耗表组查询
export function getEcGroupListByCondition(params) {
    return http({
        url: `${preApi}/ec/getEcGroupListByCondition`,
        method: "post",
        data: params
    })
}

//能耗管理-能耗表组编辑
export function editEcGroup(params) {
    return http({
        url: `${preApi}/ec/editEcGroup`,
        method: "post",
        data: params
    })
}
//能耗管理-能耗表组删除
export function deleteOneEcGroup(params) {
    return http({
        url: `${preApi}/ec/deleteOneEcGroup`,
        method: "post",
        data: params
    })
}
//能耗管理-能耗表全部名称查询
export function getEcGroupNamesListByCondition(params) {
    return http({
        url: `${preApi}/ec/getEcGroupNamesListByCondition`,
        method: "post",
        data: params
    })
}


//能耗管理-能耗表台账添加
export function addEcRecord(params) {
    return http({
        url: `${preApi}/ec/addEcRecord`,
        method: "post",
        data: params
    })
}

//能耗管理-能耗表台账编辑
export function editEcRecord(params) {
    return http({
        url: `${preApi}/ec/editEcRecord`,
        method: "post",
        data: params
    })
}

//能耗管理-能耗表台账查询
export function getEcRecordListByCondition(params) {
    return http({
        url: `${preApi}/ec/getEcRecordListByCondition`,
        method: "post",
        data: params
    })
}
//能耗管理-能耗表台账删除
export function deleteOneEcRecord(params) {
    return http({
        url: `${preApi}/ec/deleteOneEcRecord`,
        method: "post",
        data: params
    })
}
//能耗管理-能耗表台账所有ID和编号
export function getAllEcRecordIdAndCodeListByCondition(params) {
    return http({
        url: `${preApi}/ec/getAllEcRecordIdAndCodeListByCondition`,
        method: "post",
        data: params
    })
}
//能耗管理-能耗表台账依据ID查询
export function getOneRecordById(params) {
    return http({
        url: `${preApi}/ec/getOneRecordById`,
        method: "post",
        data: params
    })
}




//能耗管理-能耗分配台账添加
export function addEcAllocationRecord(params) {
    return http({
        url: `${preApi}/ec/addEcAllocationRecord`,
        method: "post",
        data: params
    })
}
//能耗管理-能耗分配台账查询
export function getEcAllocationRecordListByCondition(params) {
    return http({
        url: `${preApi}/ec/getEcAllocationRecordListByCondition`,
        method: "post",
        data: params
    })
}

//能耗管理-能耗分配台账编辑
export function editEcAllocationRecord(params) {
    return http({
        url: `${preApi}/ec/editEcAllocationRecord`,
        method: "post",
        data: params
    })
}
//能耗管理-能耗分配台账删除
export function deleteOneEcAllocationRecord(params) {
    return http({
        url: `${preApi}/ec/deleteOneEcAllocationRecord`,
        method: "post",
        data: params
    })
}

//能耗管理-能耗分配台账所有ID和编号
export function getAllAllocationEcRecordIdAndCodeListByCondition(params) {
    return http({
        url: `${preApi}/ec/getAllAllocationEcRecordIdAndCodeListByCondition`,
        method: "post",
        data: params
    })
}
//能耗管理-能耗分配台账依据ID查询
export function getOneAllocationRecordById(params) {
    return http({
        url: `${preApi}/ec/getOneAllocationRecordById`,
        method: "post",
        data: params
    })
}

//能耗管理-能耗分配台账依据record_id查询
export function getAllocationRecordByRecordId(params) {
    return http({
        url: `${preApi}/ec/getAllocationRecordByRecordId`,
        method: "post",
        data: params
    })
}


//能耗管理-能耗分类组合添加
export function addEcClassificationCombination(params) {
    return http({
        url: `${preApi}/ec/addEcClassificationCombination`,
        method: "post",
        data: params
    })
}
//能耗管理-能耗分类组合查询
export function getEcClassificationCombinationListByCondition(params) {
    return http({
        url: `${preApi}/ec/getEcClassificationCombinationListByCondition`,
        method: "post",
        data: params
    })
}

//能耗管理-能耗分类组合编辑
export function editEcClassificationCombination(params) {
    return http({
        url: `${preApi}/ec/editEcClassificationCombination`,
        method: "post",
        data: params
    })
}
//能耗管理-能耗分类组合删除
export function deleteOneEcClassificationCombination(params) {
    return http({
        url: `${preApi}/ec/deleteOneEcClassificationCombination`,
        method: "post",
        data: params
    })
}


//能耗管理-能耗抄表记录添加
export function addEcMeterRecord(params) {
    return http({
        url: `${preApi}/ec/addEcMeterRecord`,
        method: "post",
        data: params
    })
}
//能耗管理-能耗抄表记录查询
export function getEcMeterRecordListByCondition(params) {
    return http({
        url: `${preApi}/ec/getEcMeterRecordListByCondition`,
        method: "post",
        data: params
    })
}

//能耗管理-能耗抄表记录编辑
export function editEcMeterRecord(params) {
    return http({
        url: `${preApi}/ec/editEcMeterRecord`,
        method: "post",
        data: params
    })
}
//能耗管理-能耗抄表记录删除
export function deleteOneEcMeterRecord(params) {
    return http({
        url: `${preApi}/ec/deleteOneEcMeterRecord`,
        method: "post",
        data: params
    })
}

//能耗管理-能耗抄表记录导出
export function exportEcMeterRecordExcel(params) {
    return http({
        url: `${preApi}/ec/exportEcMeterRecordExcel`,
        method: "post",
        data: params,
        responseType: 'blob'
    })
}

//能耗管理-能耗分类查询
export function getEcClassificationQueryListByCondition(params) {
    return http({
        url: `${preApi}/ec/getEcClassificationQueryListByCondition`,
        method: "post",
        data: params
    })
}
//能耗管理-能耗分类查询导出
export function exportEcClassificationQueryExcel(params) {
    return http({
        url: `${preApi}/ec/exportEcClassificationQueryExcel`,
        method: "post",
        data: params,
        responseType: 'blob'
    })
}
//能耗管理-能耗分类查询记录删除
export function deleteOneEcClassificationQuery(params) {
    return http({
        url: `${preApi}/ec/deleteOneEcClassificationQuery`,
        method: "post",
        data: params
    })
}


//能耗管理-能耗分类-根据项目查询所有的能耗类型
export function getAllTypesByUserdep(params) {
    return http({
        url: `${preApi}/ec/getAllTypesByUserdep`,
        method: "post",
        data: params
    })
}
//能耗管理-能耗分类-根据项目查询所有的能耗分类
export function getAllClassificationsByUserdep(params) {
    return http({
        url: `${preApi}/ec/getAllClassificationsByUserdep`,
        method: "post",
        data: params
    })
}

//能耗管理-能耗历史分析查询
export function getEcHistoryAnalysisListByCondition(params) {
    return http({
        url: `${preApi}/ec/getEcHistoryAnalysisListByCondition`,
        method: "post",
        data: params
    })
}
//能耗管理-能耗历史分析查询导出
export function exportEcHistoryAnalysisExcel(params) {
    return http({
        url: `${preApi}/ec/exportEcHistoryAnalysisExcel`,
        method: "post",
        data: params,
        responseType: 'blob'
    })
}
//能耗管理-能耗历史分析查询记录删除
export function deleteOneEcHistoryAnalysis(params) {
    return http({
        url: `${preApi}/ec/deleteOneEcHistoryAnalysis`,
        method: "post",
        data: params
    })
}