export default {
    data() {
        return {
            ecTypeList: ['电', '水', '燃气'],
            //能耗表编号对照表
            ecCodeContrastList: {'电':'D','水':'S','燃气':'R'},
            //形式
            ecMeterForm:{'电':['普通电表','插卡电表','远传电表'],'水':['普通水表','插卡水表','远传水表'],'燃气':['普通燃气表','插卡燃气表','远传燃气表']},
            //等级
            ecMeterLevel:{
                '电':['0．1','0．2','0．5','1．0','1．5','2．5','5．0'],
                '水':['A','B','C','D'],
                '燃气':['一类天然气表','二类天然气表','三类天然气表','四类天然气表']
            },
            //级数
            ecLevelList: [1, 2, 3, 4, 5],
            ecRegionList: ['公区', '专属区'],
            ecPositionList: {'公区': ['楼栋', '园区', '地下车库', '综合'], '专属区': ['商铺', '住户', '服务中心']},
            ecCategoryList: {
                '公区': {
                    '电': ['照明用电',
                        '电梯用电',
                        '暖通用电',
                        '消防用电',
                        '二次供水用电',
                        '排水用电',
                        '景观用电',
                        '商用用电',
                        '其它用电'],
                    '水': ['暖通用水',
                        '消防用水',
                        '卫生间用水',
                        '清洁用水',
                        '景观用水',
                        '绿化用水',
                        '商用用水',
                        '其它用水'],
                    '燃气': ['暖通用气',
                        '餐饮用气',
                        '其它用气']
                }
                , '专属区': {
                    '电': ['照明用电',
                        '动力用电'],
                    '水': ['生活用水',
                        '其它用水'],
                    '燃气': ['生活用气',
                        '餐饮用气']
                }
            },
            ecStatusList: ['正常', '异常', '停用', '作废'],
            ecFeeSelectList: ['是', '否'],
            ecClassifyList: ['公共用电','电梯用电','二次供水用电','车库照明用电','中央空调用电','服务中心用电','公共用水',
                '园区用水','车库清洁用水','中央空调用水','燃气用量','商户用电','住户用电','住户用水','其它能耗'],
        }
    },
    methods: {}
}